import orgService from '../../../../services/notisphere/organization'
import moment from 'moment-timezone'

export default {
    data() {
        return {
            item: {
                id: null,
                name: null,
                notisphere_id: '',
                address1: null,
                address2: '',
                city: null,
                state: null,
                zip: null,
                stt: 'VERIFIED',
                isSSO: false,
                idpName: null,
                createdBy: null,
                effectiveDate: null,

                domains: [],
                ad_first_name: null,
                ad_last_name: null,
                ad_email: null,

                contract_start_date: null,
                contract_end_date: null,
                contract_upload_date: null,
                signatory: '',
                accounttype: '',
                accountsize: '',
                programtype: '',
                primarydistributor: ''
            },
            hideDetails: false,
            error: '',
            formType: 'INSERT',
            filesUpload: [],
            filesUploadOriginal: [],
            accountsizelst: [],
            programtypelst: [],
            primarydistributorlst: []
        }
    },
    computed: {
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'New Provider',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Provider',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
        _status: {
            get() {
                return this.item.stt == 'VERIFIED' ? true : false
            },
            set(val) {
                if (val == true) {
                    this.item.stt = 'VERIFIED'
                }
                else {
                    this.item.stt = 'DEACTIVATED'
                }
            }
        }
    },

    methods: {
        isSignatory() {

        },
        fetchProviderOrgMetaDataFromService() {
            orgService.getProviderOrgMetaData().then(res => {
                if (!res.error) {
                    let _lookupValues = res.data.d.lookup_values

                    let _account_size = _lookupValues.find(x => x.name === 'Account Size')
                    if (_account_size) {
                        this.accountsizelst = _account_size.list_vals
                    }
                    let _program_type = _lookupValues.find(x => x.name === 'Program Type')
                    if (_program_type) {
                        this.programtypelst = _program_type.list_vals
                    }
                    let _primary_discributor = _lookupValues.find(x => x.name === 'Primary Distributor')
                    if (_primary_discributor) {
                        this.primarydistributorlst = _primary_discributor.list_vals
                    }
                }
            }).finally(() => {
                this.initialLoading = false
            })
        },
        removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
            var DatesList = []
            if (this.formType == 'INSERT') {
                if (this.filesUpload.length > 0) {
                    this.item.contract_upload_date = new Date()
                }
                else {
                    this.item.contract_upload_date = null
                }
            }
            else if (this.formType == 'UPDATE') {
                if (this.filesUpload.length > 0) {
                    this.filesUpload.forEach((x, i) => {
                        if (x.created_datetime != null)
                            DatesList.push(x.created_datetime)
                        else
                            DatesList.push(new Date())
                    })
                    var Dates = ''
                    for (let i = 0; i < DatesList.length; i++) {
                        if (Dates == '') {
                            Dates = DatesList[i]
                        }
                        else if (Dates > DatesList[i]) {
                            Dates = DatesList[i]
                        }
                    }
                    this.item.contract_upload_date = Dates
                }
                else {
                    this.item.contract_upload_date = null
                }
            }
        },
        async handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            var isError = false
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    if (uploadedFiles[i].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || uploadedFiles[i].type == 'application/pdf') {
                        if (this.formType == 'UPDATE' || this.formType == 'RES_UPDATE') {
                            this.filesUpload.push({
                                name: uploadedFiles[i].name,
                                type: uploadedFiles[i].type,
                                size: uploadedFiles[i].size,
                                file: uploadedFiles[i],
                            })
                        }
                        else {
                            this.filesUpload.push({
                                file: uploadedFiles[i]
                            })
                        }
                    }
                    else {
                        isError = true
                    }
                }
            }
            if (isError) {
                this._showToast('The file extension should be .PDF or .docx', { variant: 'danger' })
            }
            var DatesList = []
            if (this.filesUpload.length > 0) {
                this.filesUpload.forEach((x, i) => {
                    if (x.created_datetime != null)
                        DatesList.push(x.created_datetime)
                    else
                        DatesList.push(new Date())
                })
                var Dates = ''
                for (let i = 0; i < DatesList.length; i++) {
                    if (Dates == '') {
                        Dates = DatesList[i]
                    }
                    else if (Dates > DatesList[i]) {
                        Dates = DatesList[i]
                    }
                }
                this.item.contract_upload_date = Dates
            }
            else {
                this.item.contract_upload_date = null
            }
            this.$refs.files.value = ''
        },
        DateValidation(value, mode) {
            if (value.contract_start_date != null && value.contract_end_date != null) {
                if (new Date(value.contract_end_date) >= new Date(value.contract_start_date)) {
                    this.error = ''
                    this.hideDetails = false
                }
                else {
                    this.error = 'Contract Start Date should not be greater than Contract End Date.'
                    this.hideDetails = true
                }
            } else {
                this.hideDetails = false
                this.error = ''
                return true
            }
        },
        forceRerenderInsert() {
            this.fetchProviderOrgMetaDataFromService()
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id) {
            this.fetchProviderOrgMetaDataFromService()
            this.formType = 'UPDATE'
            this.getProvider(id)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if (isValid && this.formType == 'INSERT') {
                if (this.item.domains.length > 0) {
                    this.createProvider()
                } else {
                    this._showToast('Domains list cannot be empty', { variant: 'danger' })
                }
            }
            if (isValid && this.formType == 'UPDATE') {
                this.updateProvider()
            }
        },
        updateProvider() {
            let formData = new FormData()
            formData.append('id', this.item.id)
            formData.append('name', this.item.name)
            formData.append('notisphereid', this.item.notisphere_id)
            formData.append('add1', this.item.add1)
            formData.append('add2', this.item.add2 != undefined ? this.item.add2 : '')
            formData.append('city', this.item.city)
            formData.append('state', this.item.state)
            formData.append('zip', this.item.zip)
            formData.append('stt', this.item.stt)
            formData.append('isSSO', this.item.isSSO == null ? false : this.item.isSSO)
            formData.append('idpName', this.item.idpName)
            formData.append('startdate', this.item.contract_start_date ? moment(this.item.contract_start_date).format('YYYY/MM/DD') : '')
            formData.append('enddate', this.item.contract_end_date ? moment(this.item.contract_end_date).format('YYYY/MM/DD') : '')
            formData.append('signatory', this.item.signatory)
            formData.append('accounttype', this.item.accounttype != undefined ? this.item.accounttype : '')
            formData.append('accountsize', this.item.accountsize != undefined ? this.item.accountsize : '')
            formData.append('programtype', this.item.programtype != undefined ? this.item.programtype : '')
            formData.append('primarydistributor', this.item.primarydistributor != undefined ? this.item.primarydistributor : '')
            this.filesUpload.forEach((x, i) => {
                if (x.file != undefined) {
                    formData.append('document', x.file)
                }
                else {
                    formData.append('actualfiles[' + i + '].organizationdocumentid', x.organization_document_id)
                    formData.append('actualfiles[' + i + '].filename', x.name.split(x.extension)[0])
                    formData.append('actualfiles[' + i + '].organizationid', x.organization_id)
                    formData.append('actualfiles[' + i + '].s3path', x.pathS)
                    formData.append('actualfiles[' + i + '].extension', x.extension)
                    formData.append('actualfiles[' + i + '].mimetype', x.type)
                    formData.append('actualfiles[' + i + '].fileSize', x.size)
                    formData.append('actualfiles[' + i + '].isSelected', 1)
                }
            })
            this.filesUploadOriginal.forEach((x, i) => {
                formData.append('files[' + i + '].organizationdocumentid', x.organization_document_id)
                formData.append('files[' + i + '].filename', x.name.split(x.extension)[0])
                formData.append('files[' + i + '].organizationid', x.organization_id)
                formData.append('files[' + i + '].s3path', x.pathS)
                formData.append('files[' + i + '].extension', x.extension)
                formData.append('files[' + i + '].mimetype', x.type)
                formData.append('files[' + i + '].fileSize', x.size)
                formData.append('files[' + i + '].isSelected', 1)
            })
            var isUpdate = true
            if (this.item.contract_start_date != null && this.item.contract_end_date != null) {
                if (new Date(this.item.contract_end_date) < new Date(this.item.contract_start_date)) {
                    isUpdate = false
                }
            }
            if (isUpdate) {
                orgService.updateProvider(formData).then(resp => {
                    if (!resp.error) {
                        this.$emit('onCompleted', 'UPDATE')
                        this.$refs.modal.hide()
                    }
                })
            }
        },
        createProvider() {
            let formData = new FormData()
            formData.append('name', this.item.name)
            formData.append('notisphereid', this.item.notisphere_id)
            formData.append('add1', this.item.add1)
            formData.append('add2', this.item.add2 != undefined ? this.item.add2 : '')
            formData.append('city', this.item.city)
            formData.append('state', this.item.state)
            formData.append('zip', this.item.zip)
            formData.append('startdate', this.item.contract_start_date ? moment(this.item.contract_start_date).format('YYYY/MM/DD') : '')
            formData.append('enddate', this.item.contract_end_date ? moment(this.item.contract_end_date).format('YYYY/MM/DD') : '')
            formData.append('firstname', this.item.ad_first_name)
            formData.append('lastname', this.item.ad_last_name)
            formData.append('isSSO', this.item.isSSO == null ? false : this.item.isSSO)
            formData.append('idpName', this.item.idpName)
            formData.append('email', this.item.ad_email != null ? this.item.ad_email : '')
            formData.append('signatory', this.item.signatory)
            formData.append('accounttype', this.item.accounttype != undefined ? this.item.accounttype : '')
            formData.append('accountsize', this.item.accountsize != undefined ? this.item.accountsize : '')
            formData.append('programtype', this.item.programtype != undefined ? this.item.programtype : '')
            formData.append('primarydistributor', this.item.primarydistributor != undefined ? this.item.primarydistributor : '')
            this.filesUpload.forEach((x, i) => {
                formData.append('document', x.file)
            })
            this.item.domains.forEach((x, i) => {
                formData.append('domains', x)
            })
            orgService.createProvider(formData).then(resp => {
                if (!resp.error) {
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
                else {
                    if (resp.data.c == 'err_user_email_exists') {
                        this.$refs.observer.setErrors({ 'ad_email': ['The user already exists.'] })
                    }

                }
            })
        },
        getProvider(id) {
            orgService.getProvider(id).then(resp => {
                if (!resp.error) {
                    this.item = resp.data.d
                    this.item.primarydistributor = resp.data.d.primarydistributor == 'null' ? '' : resp.data.d.primarydistributor
                    this.item.accountsize = resp.data.d.accountsize == 'null' ? '' : resp.data.d.accountsize
                    this.item.programtype = resp.data.d.programtype == 'null' ? '' : resp.data.d.programtype
                    this.filesUploadOriginal = []
                    this.item.document.forEach((x, i) => {
                        if (x.extension != null) {
                            this.filesUpload.push({
                                name: x.filename + x.extension,
                                extension: x.extension,
                                type: x.mimetype,
                                size: x.fileSize,
                                path: x.signed_url,
                                pathS: x.s3path,
                                organization_document_id: x.organization_document_id,
                                organization_id: x.organization_id,
                                created_datetime: x.created_datetime
                            })
                            this.filesUploadOriginal.push({
                                name: x.filename + x.extension,
                                extension: x.extension,
                                type: x.mimetype,
                                size: x.fileSize,
                                path: x.signed_url,
                                pathS: x.s3path,
                                organization_document_id: x.organization_document_id,
                                organization_id: x.organization_id,
                                created_datetime: x.created_datetime
                            })
                        }
                    })
                    this.$nextTick(() => {
                        this.$refs.modal.show()
                    })
                }
            })
        },
        removeDomain(index) {
            this.item.domains.splice(index, 1)
        },
        addDomain() {
            this.item.domains.push('')
        },
        isChange(eve) {
            if (eve.target.checked) {
                this.item.isSSO = true
                this.item.idpName = ''
            }
            else {
                this.item.isSSO = false
                this.item.idpName = ''
            }
        },
    }
}